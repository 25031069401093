body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: none !important;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 15px;
}
.lds-ellipsis div {
  position: absolute;
  top: 2px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: -10px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: -10px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 10px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 8px;
  animation: lds-ellipsis3 0.6s infinite;
}

#white {
  text-decoration: none !important;
  color: #fff !important;
}

#none {
  text-decoration: none !important;
  color: #757575;
}

a {
  color: inherit !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

#red {
  background-color: rgb(209, 43, 14);
  color: #fff;
}

#blue {
  background-color: rgb(60, 76, 218);
  color: #fff;
}

input:focus {
  outline: none !important;
}

#profile-header {
  padding: 7px;
  border-radius: 30px;
  cursor: pointer;
}

#profile-header:hover {
  background-color: #ececec;
}

#standard-basic1,
#standard-basic2,
#standard-basic3,
#standard-basic4,
#standard-basic5,
#standard-basic6,
#standard-basic7,
#standard-basic8,
#standard-basic9,
#standard-basic10 {
  display: flex;
  align-items: center;
  width: 280px;
  font-size: 16px;
  line-height: 26px;
  color: #b7b9d4;
  padding: 4px 10px;
  position: relative;
  background: transparent;
  cursor: text;
  margin-top: 4px;
  margin-bottom: 4px;
}

.MuiAppBar-colorPrimary {
  background-color: #003366 !important;
}

footer {
  margin-top: 200px;
}

.hover:hover {
  cursor: pointer !important;
}

.flex {
  display: flex !important;
}

header {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.MuiIconButton-root {
  border-radius: 20px !important;
}

#loginPage {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#loginSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

@media only screen and (min-width: 768px) {
  #loginSection {
    min-width: 600px;
  }

  #imageSection {
    min-width: 400px;
  }

  .videoWrapper {
    width: 800px;
    height: 450px;
  }
}

@media only screen and (max-width: 1060px) {
  #imageSection {
    display: none;
  }

  .videoWrapper {
    width: 320px;
    height: 180px;
  }
}

@media only screen and (max-width: 860px) {
  .videoWrapper {
    width: 320px;
    height: 180px;
  }
}

#root {
  background-color: #fff;
}

.MuiAppBar-colorPrimary {
  background-color: #fff !important;
}

.MuiTableContainer-root {
  box-shadow: unset !important;
}

.MuiCard-root {
  /* border: 1px solid var(--primary-dark); */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 5%;
}

#sektion {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.row {
  margin: 50px 0 0 0;
}

.status-icon {
  background-color: #0ff;
  box-shadow: 2px 8px 18px 20px #0ff;
}

.videoWrapper iframe {
  width: 100%;
  height: 100%;
}
